import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const [t] = useTranslation("global");

  return (
    <>
      <div
        dir="rtl"
        className="container-fluid bg-dark text-white-50 footer pt-4  wow fadeIn "
        data-wow-delay="0.1s"
      >
        <div className="pattern_ab"></div>
        <div className="container py-4 z-10">
          <div className="row g-5 justify-content-center px-lg-4">
            <div id="contact" className="col-lg-6 col-md-6">
              <h5 className="text-white mb-4 ">{t("menu-contact")}</h5>
              <a
                href={"https://wa.me/989377547384"}
                dir="ltr"
                target="_blank"
                className="text-end text-white"
                rel="noopener"
              >
                0937 754 7384
                <i className="fab fa-whatsapp ms-2 fs-4"></i>
                <br />
              </a>
              <div className="d-flex pt-4">
                <a className="btn btn-outline-light fs-5 btn-social">
                  <i className="fab fa-telegram"></i>
                </a>
                <a className="btn btn-outline-light fs-5 btn-social">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <h5 className="text-white mb-4 ">{t("footer-main-link")}</h5>
              <Link className="btn btn-link text-white-50 text-end " to="/">
                {t("menu-home")}
              </Link>
              <Link className="btn btn-link text-white-50 text-end " to="/map">
                {t("menu-map")}
              </Link>
              <Link
                className="btn btn-link text-white-50 text-end "
                to="/about"
              >
                {t("menu-about")}
              </Link>
              <Link
                to="/contact"
                className="btn btn-link text-white-50 text-end "
              >
                {t("menu-contact")}
              </Link>
            </div>
          </div>
        </div>
     
      </div>
      <div className=" z-10 text-blank bg-white p-1 w-full">
          <div class="  text-center gap-2 lg:gap-7 flex items-center flex-wrap lg:pb-0 justify-center sm:justify-around">
            <p class="text-center">{t("copy-right")}</p>
            <a
              href="https://sirir.ir/"
              target="_blank"
              rel="noreferrer"
              dir="ltr"
              className="flex gap-1 items-center"
            >
              Powered By{" "}
              <img
                src="https://sirir.ir/logo.png"
                className="w-8 object-contain items-center mb-1"
                alt="Sirir logo"
              />
              Sirir
            </a>
          </div>
        </div>
      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
        <i className="bi bi-arrow-up"></i>
      </a>
    </>
  );
};

export default Footer;
